import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/navLogo.svg'
import _imports_1 from '@/assets/icons/logOutIcon.svg'


const _hoisted_1 = { class: "navigation-menu" }
const _hoisted_2 = { class: "navigation-menu-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  src: _imports_0
}
const _hoisted_6 = { class: "navigation-component-box-links" }
const _hoisted_7 = {
  key: 0,
  class: "navigation-component-hamburger-list"
}
const _hoisted_8 = { class: "navigation-component-hamburger-list-options" }
const _hoisted_9 = {
  key: 9,
  class: "navigation-component-hamburger-list-options-option-column"
}
const _hoisted_10 = { class: "navigation-component-hamburger-list-options-option-footer" }
const _hoisted_11 = { class: "info-text center-text" }
const _hoisted_12 = {
  key: 0,
  id: "sub-navigation-box"
}

import axios from 'axios';
import router from '@/router';
import { RouteRecordName } from 'vue-router';
import { ref, onMounted, onBeforeUnmount, useTemplateRef } from 'vue';
import { useDebounceFunc } from 'vortec-components-front';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationComponent',
  setup(__props) {

const navigationOpened = ref(false);
const navigation = useTemplateRef<HTMLDivElement>('navigation');

function includesName(outter: RouteRecordName | null | undefined, inner: string) {
  if (!outter) return false;
  const outterString = outter.toString();
  return outterString.includes(inner);
}

function logOutUser() {
  axios.post('/api/logout').then(() => {
    window.userData = null;
    router.push({ name: 'AuthPage' });
  });
}

const previousScrollY = ref(0);

let navigationElement: HTMLElement | null = null;
let tableRowElement: HTMLElement | null = null;
let table: HTMLElement | null = null;

const cacheElements = () => {
  navigationElement = navigation.value;
  tableRowElement = document.querySelector('.table-row-header-nav');
  table = document.querySelector('.table');
};

const handleScroll = () => {
  if (!navigationElement) {
    cacheElements();
  }

  if (!navigationElement) {
    return;
  }

  const scrollY = window.scrollY;
  const isScrollingUp = scrollY < previousScrollY.value;
  const initSpace = navigationElement.clientHeight;
  const rowTop = tableRowElement?.getBoundingClientRect().top || 0;
  const applySmoothTransition = (element: HTMLElement, duration = '0.2s') => {
    element.style.transition = `transform ${duration} ease-out`;
  };
  if (scrollY > initSpace) {
    if (isScrollingUp) {
      navigationElement.style.transform = 'translateY(0)';
      applySmoothTransition(navigationElement);
      if (tableRowElement) {
        if (rowTop <= 0) {
          tableRowElement.style.transform = `translateY(${initSpace}px)`;
          applySmoothTransition(tableRowElement);
        } else if (table) {
          const tableTop = table.getBoundingClientRect().top;
          if (tableTop > 0 && tableTop < initSpace) {
            const spaceNeeded = tableTop - initSpace;
            tableRowElement.style.transform = `translateY(${Math.abs(spaceNeeded)}px)`;
            applySmoothTransition(tableRowElement);
          } else if (tableTop > 0 && tableTop > initSpace) {
            tableRowElement.style.transform = `translateY(0px)`;
            applySmoothTransition(tableRowElement, '0.0s');
          }
        }
      }
    } else {
      navigationElement.style.transform = 'translateY(-100%)';
      applySmoothTransition(navigationElement);
      if (tableRowElement) {
        tableRowElement.style.transform = 'translateY(0)';
        applySmoothTransition(tableRowElement, '0.0s');
      }
    }

    if (!navigationElement.style.position || navigationElement.style.position !== 'sticky') {
      setTimeout(() => {
        if (!navigationElement) {
          cacheElements();
        }

        if (!navigationElement) {
          return;
        }

        navigationElement.style.position = 'sticky';
        applySmoothTransition(navigationElement);
      }, 200);
    }

    if (tableRowElement && rowTop < 0) {
      applySmoothTransition(navigationElement);
      tableRowElement.classList.add('box-shadow-bottom');
    }
  } else {
    if (scrollY <= 1) {
      navigationElement.style.position = 'relative';
      navigationElement.style.transition = 'none';
      navigationElement.style.transform = 'translateY(0)';
      if (tableRowElement) {
        tableRowElement.style.transition = 'none';
        tableRowElement.style.transform = 'translateY(0)';
        tableRowElement.classList.remove('box-shadow-bottom');
      }
    } else {
      if (tableRowElement) {
        tableRowElement.style.transition = 'none';
        tableRowElement.style.transform = `translateY(${scrollY}px)`;
      }
      navigationElement.style.transition = 'none';
      navigationElement.style.transform = `translateY(${scrollY}px)`;
    }
  }

  previousScrollY.value = scrollY;
};

const dropdownExpanded = ref<string[]>([]);

const handleDropdown = (name: string) => {
  if (dropdownExpanded.value.includes(name)) {
    dropdownExpanded.value = dropdownExpanded.value.filter((item) => item !== name);
  } else {
    dropdownExpanded.value = [...dropdownExpanded.value, name];
  }
};

onMounted(() => {
  window.addEventListener('scroll', useDebounceFunc(handleScroll, 25), { passive: true, capture: false, once: false });
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("header", {
    class: "navigation",
    ref_key: "navigation",
    ref: navigation
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: {
            name: 'LandingDashboard',
          },
          class: "navigation-menu-box-logo"
        }, {
          default: _withCtx(() => [
            (
              _ctx.window.userData &&
              _ctx.window.userData.tenant &&
              _ctx.window.userData.tenant.logo &&
              _ctx.window.userData.tenant.logo?.file_data
            )
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.window.userData.tenant.logo.file_data.toString()
                }, null, 8, _hoisted_3))
              : (
              _ctx.window.userData &&
              _ctx.window.userData.tenant &&
              _ctx.window.userData.tenant.icon &&
              _ctx.window.userData.tenant.icon?.file_data
            )
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.window.userData.tenant.icon.file_data.toString()
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.window.userData?.tenant.privileges.includes('subscribe'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
              name: 'SubscribersView',
            },
                class: _normalizeClass([{
              'active-navigation-list-item': includesName(_ctx.$route.name, 'SubscribersView'),
            }, "list-text-bold disabled-text"])
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Subscribers ")
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.window.userData?.tenant.privileges.includes('contact'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: {
              name: 'ContactsView',
            },
                class: _normalizeClass([{
              'active-navigation-list-item': includesName(_ctx.$route.name, 'Contact'),
            }, "list-text-bold disabled-text"])
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" Contacts ")
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.window.userData?.tenant.privileges.includes('features'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: {
              name: 'FeaturesView',
            },
                class: _normalizeClass([{
              'active-navigation-list-item': includesName(_ctx.$route.name, 'Feature'),
            }, "list-text-bold disabled-text"])
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode(" Features ")
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.window.userData?.tenant.privileges.includes('issues'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: {
              name: 'IssuesView',
            },
                class: _normalizeClass([{
              'active-navigation-list-item': includesName(_ctx.$route.name, 'Issue'),
            }, "list-text-bold disabled-text"])
              }, {
                default: _withCtx(() => _cache[20] || (_cache[20] = [
                  _createTextVNode(" Issues ")
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.window.userData?.tenant.privileges.includes('mails'))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                to: {
              name: 'MailsView',
            },
                class: _normalizeClass([{
              'active-navigation-list-item': includesName(_ctx.$route.name, 'Mail'),
            }, "list-text-bold disabled-text"])
              }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createTextVNode(" Mails ")
                ])),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["hamburger", {
          'hamburger-menu-opened': navigationOpened.value,
        }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (navigationOpened.value = true))
      }, _cache[22] || (_cache[22] = [
        _createElementVNode("div", { class: "block" }, null, -1),
        _createElementVNode("div", { class: "block" }, null, -1),
        _createElementVNode("div", { class: "block" }, null, -1)
      ]), 2),
      _createVNode(_Transition, { name: "navigation-component-hamburger-list-animation" }, {
        default: _withCtx(() => [
          (navigationOpened.value)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (navigationOpened.value = false)),
                    to: {
                name: 'ClientSelection',
              },
                    class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'ClientSelection'),
              }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode(" Client Selection ")
                    ])),
                    _: 1
                  }, 8, ["class"]),
                  (_ctx.window.userData?.tenant.privileges.includes('subscribe'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'SubscribersView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'SubscribersView'),
              }, "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode(" Subscribers ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData?.tenant.privileges.includes('contact'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'ContactsView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'Contact'),
              }, "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode(" Contacts ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData?.tenant.privileges.includes('features'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 2,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'FeaturesView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'Feature'),
              }, "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode(" Features ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData?.tenant.privileges.includes('issues'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 3,
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'IssuesView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'Issue'),
              }, "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode(" Issues ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData?.tenant.privileges.includes('mails'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 4,
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'MailsView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'Mail'),
              }, "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode(" Mails ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_router_link, {
                    to: {
                name: 'LinksManagement',
              },
                    class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'LinksManagement'),
              }, "list-text-bold disabled-text"])
                  }, {
                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createTextVNode(" Links ")
                    ])),
                    _: 1
                  }, 8, ["class"]),
                  (_ctx.window.userData?.tenant.privileges.includes('system-emails'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 5,
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'SystemEmailsView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'SystemEmail'),
              }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode(" System Emails ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (
                _ctx.window.userData &&
                _ctx.window.userData.scopes.includes('tenant-management') &&
                (_ctx.window.userData?.tenant.privileges.includes('system-emails') ||
                  _ctx.window.userData?.tenant.privileges.includes('mails'))
              )
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 6,
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'EmailAccountsView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'EmailAccount'),
              }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                          _createTextVNode(" Email Accounts ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData && _ctx.window.userData.scopes.includes('tenant-management'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 7,
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'UsersView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'User'),
              }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                          _createTextVNode(" Users ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData && _ctx.window.userData.scopes.includes('tenant-management'))
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 8,
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (navigationOpened.value = false)),
                        to: {
                name: 'PasswordsView',
              },
                        class: _normalizeClass([{
                'active-navigation-list-item': includesName(_ctx.$route.name, 'Password'),
              }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                      }, {
                        default: _withCtx(() => _cache[33] || (_cache[33] = [
                          _createTextVNode(" Passwords ")
                        ])),
                        _: 1
                      }, 8, ["class"]))
                    : _createCommentVNode("", true),
                  (_ctx.window.userData && _ctx.window.userData.scopes.includes('system-administrator'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", {
                          class: "navigation-component-hamburger-list-options-option-column-row",
                          onClick: _cache[11] || (_cache[11] = ($event: any) => (handleDropdown('system-administrator')))
                        }, [
                          _cache[34] || (_cache[34] = _createTextVNode(" System Administrator ")),
                          _createElementVNode("div", {
                            class: _normalizeClass(["arrow", {
                    'top-arrow': dropdownExpanded.value.includes('system-administrator'),
                    'bottom-arrow': !dropdownExpanded.value.includes('system-administrator'),
                  }])
                          }, null, 2)
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass(["navigation-component-hamburger-list-options-option-column-data", {
                  'navigation-component-hamburger-list-options-option-column-data-expanded':
                    dropdownExpanded.value.includes('system-administrator'),
                }])
                        }, [
                          _createVNode(_component_router_link, {
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (navigationOpened.value = false)),
                            to: {
                    name: 'ClientsView',
                  },
                            class: _normalizeClass([{
                    'active-navigation-list-item': includesName(_ctx.$route.name, 'Clients'),
                  }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                          }, {
                            default: _withCtx(() => _cache[35] || (_cache[35] = [
                              _createTextVNode(" Clients ")
                            ])),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_router_link, {
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (navigationOpened.value = false)),
                            to: {
                    name: 'ScopesManagement',
                  },
                            class: _normalizeClass([{
                    'active-navigation-list-item': includesName(_ctx.$route.name, 'ScopesManagement'),
                  }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                          }, {
                            default: _withCtx(() => _cache[36] || (_cache[36] = [
                              _createTextVNode(" Scopes ")
                            ])),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_router_link, {
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (navigationOpened.value = false)),
                            to: {
                    name: 'PrivilegesConfiguration',
                  },
                            class: _normalizeClass([{
                    'active-navigation-list-item': includesName(_ctx.$route.name, 'PrivilegesConfiguration'),
                  }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                          }, {
                            default: _withCtx(() => _cache[37] || (_cache[37] = [
                              _createTextVNode(" Privileges Configuration ")
                            ])),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_router_link, {
                            onClick: _cache[15] || (_cache[15] = ($event: any) => (navigationOpened.value = false)),
                            to: {
                    name: 'PrivilegesManagement',
                  },
                            class: _normalizeClass([{
                    'active-navigation-list-item': includesName(_ctx.$route.name, 'PrivilegesManagement'),
                  }, "navigation-component-hamburger-list-options-option list-text-bold disabled-text"])
                          }, {
                            default: _withCtx(() => _cache[38] || (_cache[38] = [
                              _createTextVNode(" Privileges ")
                            ])),
                            _: 1
                          }, 8, ["class"]),
                          _createVNode(_component_router_link, {
                            onClick: _cache[16] || (_cache[16] = ($event: any) => (navigationOpened.value = false)),
                            to: {
                    name: 'LogsView',
                  },
                            class: "navigation-component-hamburger-list-options-option list-text-bold disabled-text"
                          }, {
                            default: _withCtx(() => _cache[39] || (_cache[39] = [
                              _createTextVNode(" Logs ")
                            ])),
                            _: 1
                          })
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: "navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-logout list-text-bold disabled-text",
                    onClick: logOutUser
                  }, _cache[40] || (_cache[40] = [
                    _createTextVNode(" Log out "),
                    _createElementVNode("img", { src: _imports_1 }, null, -1)
                  ])),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, "© " + _toDisplayString(new Date().getFullYear()) + " Vortec | All rights reserved", 1),
                    _cache[41] || (_cache[41] = _createElementVNode("div", { class: "navigation-component-hamburger-list-options-option-footer-terms" }, [
                      _createElementVNode("a", {
                        href: "https://vortec.io/terms-and-conditions",
                        class: "info-text center-text primary-text"
                      }, "Terms and conditions"),
                      _createElementVNode("a", {
                        href: "https://vortec.io/privacy-policy",
                        class: "info-text center-text primary-text"
                      }, "Privacy policy")
                    ], -1))
                  ])
                ])
              ])), [
                [_directive_click_outside, 
            () => {
              navigationOpened.value = false;
            }
          ]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_ctx.$slots['sub-navigation'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _renderSlot(_ctx.$slots, "sub-navigation")
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})