import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/reset-password/:email/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/account-setup/:token',
    name: 'AccountSetup',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AccountSetup.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/',
    name: 'LandingDashboard',
    component: () => import(/* webpackChunkName: "user" */ '../views/LandingDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/logs',
    name: 'LogsView',
    component: () => import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsView.vue'),
    meta: {
      scopes: ['system-administrator'],
    },
  },
  {
    path: '/logs/:id',
    name: 'LogsDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsDetails.vue'),
    meta: {
      scopes: ['system-administrator'],
      showNavbar: false,
    },
  },

  {
    path: '/scopes',
    name: 'ScopesManagement',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ScopesManagement.vue'),
    meta: {
      scopes: ['system-administrator'],
    },
  },

  {
    path: '/privileges-management',
    name: 'PrivilegesManagement',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/PrivilegesManagement.vue'),
    meta: {
      scopes: ['system-administrator'],
    },
  },
  {
    path: '/client-selection',
    name: 'ClientSelection',
    component: () => import(/* webpackChunkName: "user" */ '@/views/ClientSelection.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/clients',
    name: 'ClientsView',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientsView.vue'),
    meta: {
      scopes: ['system-administrator'],
    },
  },
  {
    path: '/clients/:uuid',
    name: 'ClientDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientDetails.vue'),
    meta: {
      showNavbar: false,
      scopes: ['system-administrator'],
    },
  },

  {
    path: '/users',
    name: 'UsersView',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UsersView.vue'),
    meta: {
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UserDetails.vue'),
    meta: {
      showNavbar: false,
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/passwords',
    name: 'PasswordsView',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PasswordsView.vue'),
    meta: {
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/passwords/:id',
    name: 'PasswordDetails',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PasswordDetails.vue'),
    meta: {
      scopes: ['tenant-management'],
      showNavbar: false,
    },
  },
  {
    path: '/links',
    name: 'LinksManagement',
    component: () => import(/* webpackChunkName: "user" */ '@/views/LinksManagement.vue'),
  },
  {
    path: '/email-accounts',
    name: 'EmailAccountsView',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/EmailAccountsView.vue'),
    meta: {
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/email-accounts/:id',
    name: 'EmailAccountDetails',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/EmailAccountDetails.vue'),
    meta: {
      scopes: ['tenant-management'],
      showNavbar: false,
    },
  },
  {
    path: '/privileges-configurator',
    name: 'PrivilegesConfiguration',
    component: () =>
      import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/PrivilegesConfiguration.vue'),
    meta: {
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
  },
  {
    path: '/contacts/:id',
    name: 'ContactDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },

  {
    path: '/features',
    name: 'FeaturesView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
  },
  {
    path: '/features/:id',
    name: 'FeatureDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },

  {
    path: '/issues',
    name: 'IssuesView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
  },
  {
    path: '/issues/:id',
    name: 'IssueDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionDataDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/subscribers',
    name: 'SubscribersView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SubmissionData.vue'),
  },
  {
    path: '/system-emails',
    name: 'SystemEmailsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsView.vue'),
  },
  {
    path: '/system-emails/:id',
    name: 'SystemEmailsDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/system-emails/create',
    name: 'SystemEmailsCreate',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/SystemEmailsDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/mails',
    name: 'MailsView',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailsView.vue'),
  },
  {
    path: '/mails/:id',
    name: 'MailDetails',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/mails/create',
    name: 'MailBuilder',
    component: () => import(/* webpackChunkName: "privileges" */ '@/views/privileges/MailDetails.vue'),
    meta: {
      showNavbar: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

export default router;
