<template v-if="$route.meta.scopes && $route.meta.scopes.every((scope) => window.userData.scopes.includes(scope))">
  <NavigationComponent v-if="!($route.meta.showNavbar === false)" />
  <router-view :key="router.currentRoute.value.name" />
  <div class="loader" v-if="store.loader">
    <div class="loader-box"></div>
  </div>
</template>

<script lang="ts" setup>
import { generalStore } from '@/store';
import { useRouter } from 'vue-router';

const store = generalStore();
const router = useRouter();
</script>

<style lang="scss">
@import '@/scss/main.scss';
</style>
